import React from "react";
import styled from "styled-components";
import { Subheader } from "./Subheader";

const Wrapper = styled.div`
  padding-top: 1rem;
  .card-container {
    display: grid;
    grid-template-columns: repeat(
      auto-fill,
      minmax(${({ minWidth }) => minWidth}, 1fr)
    );
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    margin-bottom: -3rem;
  }
`;

export default function CardContainer({
  items,
  className,
  render,
  minWidth = "21rem",
}) {
  return (
    <Wrapper className={className} minWidth={minWidth}>
      <div className="card-container">{items.map((item) => render(item))}</div>
    </Wrapper>
  );
}
