import * as React from "react";
import Layout from "../components/Layout";
import { Col, Row } from "antd";
import { graphql } from "gatsby";
import CardContainer from "../components/CardContainer";
import styled from "styled-components";
import Card from "../components/Card";
import { Header } from "../components/Header";
import {columnProps, getPostsFromQuery, getReadableDate} from "../helpers";
import { Text } from "../components/Text";
import { LinkWithArrow } from "../components/LinkWithArrow";
import {Navigation} from "../components/Navigation";

const Wrapper = styled.div`
  .color-background {
    height: 100%;
  }
  .container:last-child {
    padding-bottom: 5rem;
  }
`;

const OpenSourceCardLinkWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const OpenSource = ({ data }) => {
  const posts = getPostsFromQuery(data);
  return (
    <Layout>
      <Wrapper>
        <div className="white-background">
          <Row className="content-row">
            <Col {...columnProps}>
              <Navigation />
              <div>
                <h1>Computronium</h1>
                <p>One day I got too sick of reading AWS/GCP/Azure docs and decided to fix it once and for all.</p>
                <p>
                  All I've ever wanted for machine learning is to have a sensibly configured Ubuntu machine running in the cloud, with a GPU, as quickly as possible, for as little money as possible, and without having to worry about any of the fiddly details.
                  I absolutely do not want to think about VPCs, AZs, which file system to put on which RAID level of my striped EBS volumes, or anything like that.
                </p>
                <p>So we made computronium. It looks something like this</p>
                <pre style={{"backgroundColor": "#DDD"}}>
                  computronium run \<br/>
                  --command "nvidia-smi" \<br/>
                  --image my_image \<br/>
                  --machine gpu_gb:16,cpus:2
                </pre>
                <p>That will start a command using the docker image named my_image somewhere (our local cluster if there is space, otherwise the correct instance type on AWS or GCP), and then run nvidia-smi.</p>
                <p>Specifying the exact GPU type and provider is as simple as changing the --machine flag to, for example, "gpu_type:v100,provider:aws"</p>
                <p>Computronium takes care of everything else--making sure machines dont get left running, ensuring your command runs to completion, etc--and <i>it does it without requiring any 3rd parties or any persistent services or any new concepts.</i> It only requires SSH and python.</p>
                <p>We use computronium in production every day and are currently working on open-sourcing it. <a href={"mailto:josh@generallyintelligent.ai"}>Send me a message</a> if you'd like to try it out!</p>

                <h1>Self-supervised PyTorch models</h1>
                <p>We've implemented many of the most popular self-supervised image models in a single simple framework.
                  Currently has support for MoCo (v1 and v2), SimCLR, BYOL, EqCo, and VICReg.
                </p>
                <p>
                  Getting started is ridiculously easy. This code trains a ResNet-18 with MoCo v2 on STL-10:
                </p>
                <pre style={{"backgroundColor": "#DDD"}}>
import os<br/>
import pytorch_lightning as pl<br/>
from moco import MoCoMethod<br/>
<br/>
os.environ["DATA_PATH"] = "~/data"<br/>
<br/>
model = MoCoMethod()<br/>
trainer = pl.Trainer(gpus=1, max_epochs=320)<br/>
trainer.fit(model)<br/>
trainer.save_checkpoint("example.ckpt")<br/>
                </pre>
                <p>Check it out here: <a href={
                  "https://github.com/untitled-ai/self_supervised"
                }>https://github.com/untitled-ai/self_supervised</a> </p>
                <h1>Jupyter Ascending</h1>
                <p>Jupyter Ascending lets you edit Jupyter notebooks from your favorite editor, then instantly sync and execute that code in the Jupyter notebook running in your browser.</p>
                <img style={{"width": "100%"}}
  src="/jupyter_ascending_demo.gif"
  alt="Jupyter Ascending Demo"
/>

                  <p>It's the best of both worlds--the autocomplete, keybindings, and refactoring tools you love in your favorite editor, plus the great visualization abilities of a Jupyter notebook.</p>
                  <p>Combined with basic syncing of your code to a remote server, you can have all the power of a beefy dev-server with all the convenience of editing code locally.</p>
                  <p>We mostly made it because the PyCharm notebook support has historically been quite buggy, and some team members use VIM anyway.</p>
                  <p>Check it out here: <a href={
                  "https://github.com/untitled-ai/jupyter_ascending"
                }>https://github.com/untitled-ai/jupyter_ascending</a> </p>
              </div>
            </Col>
          </Row>
        </div>
      </Wrapper>
    </Layout>
  );
};

export const query = graphql`
  query {
    allMdx(
      filter: { slug: { regex: "/open-source/" } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          id
          excerpt
          body
          fields {
            slug
          }
          frontmatter {
            date
            tags
            snippet
            title
          }
          fileAbsolutePath
        }
      }
    }
  }
`;

export default OpenSource;
