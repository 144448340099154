import { navigate } from "gatsby-link";
import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  .material-icons {
    vertical-align: middle;
    font-size: 16px;
  }
`;

export const LinkWithArrow = ({ className, to, children }) => {
  return (
    <Wrapper
      className={className}
      onClick={(e) => {
        e.stopPropagation();
        navigate(to);
      }}
    >
      <a href={to}>
        {children} <span className="material-icons">arrow_forward</span>
      </a>
    </Wrapper>
  );
};
