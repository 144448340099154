import styled from "styled-components";

export const Subheader = styled.div`
  padding-bottom: 1rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: bold;
  color: hsl(0, 0%, 75%);
  font-size: 0.9rem;
`;
