import styled from "styled-components";

export const Header = styled.h1`
  font-size: 3rem;
  padding: 25px 0;

  @media (max-width: 450px) {
    font-size: 2.5rem;
  }
`;
